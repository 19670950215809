import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { ContainerContextProvider } from "../store/ContainerContext";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ContainerContextProvider>
      {element}
    </ContainerContextProvider>
  </ApolloProvider>
);
