import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/residential\/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/residential\/property\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/commercial\/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/commercial\/property\/to-rent/)) {
      return false;
    }
  
    return true;
};